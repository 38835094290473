define("a24-staffshift-subdomain-sysadmin/controllers/index/registration-bodies", ["exports", "a24-ember-lib/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["name", "status", "position_types", "country_code", "page", "sortBy", "vis_cols", "savedSearchKey"],
        name: "",
        status: "",
        position_types: "",
        country_code: "",
        page: "1",
        sortBy: "name::asc",
        vis_cols: "1,2,3,4",
        savedSearchKey: ""
    });
});